import { LiveEventCodesQuery, ProgramAccess } from '@gql/generated';
import crypto from 'crypto';
import cloneDeep from 'lodash/cloneDeep';
import { isPast } from 'date-fns/isPast';
import { parse } from 'date-fns/parse';
import { isValid } from 'date-fns/isValid';
import { isFuture } from 'date-fns/isFuture';
import { DISABLED_PROGRAMS } from './constants';
import { formatInTimeZone } from 'date-fns-tz';

export function timeZonedFormat(date, formatString) {
  // Get the local timezone
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Convert the date to the local timezone
  return formatInTimeZone(date, localTimeZone, formatString);
}

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const isMobile = function () {
  const agent = navigator.userAgent || navigator.vendor || window['opera'];
  if (/Mobile|iP(hone|od|ad)|Android/i.test(agent)) {
    return true;
  }
  return false;
};

export const generateEpassiMac = (data) => {
  return crypto.createHash('sha512').update(data).digest('hex');
};

export const generateUserKey = (userId) => {
  if (userId) {
    return crypto
      .createHmac('sha256', process.env.USE_NATIVE_API_SECRET)
      .update(userId)
      .digest('base64');
  }

  return null;
};

export function hexToRgbA(hex, alpha = 1) {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return (
      'rgba(' +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
      `,${alpha})`
    );
  }
  throw new Error('Bad Hex');
}

export function prependHostName(path) {
  if (String(path).startsWith('/')) {
    return window.location.origin + path;
  }

  return path;
}

export const isParentElement = (parent: HTMLElement, child: HTMLElement) => {
  if (
    !child ||
    !parent ||
    child?.parentElement === null ||
    child?.parentElement instanceof HTMLBodyElement
  ) {
    return false;
  } else if (parent?.contains(child)) {
    return true;
  } else {
    return isParentElement(parent, child?.parentElement);
  }
};

export const findParentElement = (el: HTMLElement, selector) => {
  if (el) {
    if (el.parentElement?.matches(selector)) {
      return el.parentElement;
    }
    return findParentElement(el.parentElement, selector);
  }

  return null;
};

export const loadAddresses = (address) => {
  const config = {
    method: 'get',
    headers: {},
  };
  fetch(
    `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${address}&types=geocode&key=AIzaSyDTIqhUmW_Jx5E0CWOzIN6ZNNEtRqudfBo`,
    { ...config }
  );
};

export const normalizePrograms = (items) => {
  return cloneDeep(items).reduce((mergedList, item: ProgramAccess) => {
    if (item.program && DISABLED_PROGRAMS.includes(item.program.slug)) {
      // EXCLUDE DISABLED PROGRAMS
      return mergedList;
    } else if (item.program) {
      // HANDLE PROGRAMS
      if (item.program.lede && !item.program.shortDescription) {
        item.program.shortDescription = item.program.lede
          .replace(/<\/?[^>]+(>|$)/g, '')
          .replace(/(\r\n|\n|\r)/gm, '')
          .replace(/&nbsp;/gi, '');
      }
      const lang = item.program.language || 'se';
      if (item.program.slug === 'pausa-smart') {
        if (isPast(item.accessUntil)) {
          item.path = `/${lang}/pausasmart`;
        } else {
          item.path = `/${lang}/pausasmart/videos`;
        }
      } else if (item.program?.legacy) {
        item.path = `/${lang}/program/${item.program.slug}`;
      } else {
        item.path = `/${lang}/programs/${item.program.slug}/progress`;
      }
      item.progressPercentage = item.progressPercentage || 0;
    }

    mergedList.push(item);
    return mergedList;
  }, []);
};

export const isEnabledOnPage = (
  paths: Array<{
    path: string;
    exact: boolean;
    validate?(): boolean;
  }>,
  pathname: string
) => {
  return paths.some((item) => {
    if (item.exact) {
      const isPage = item.path === pathname;
      if (isPage) {
        if (typeof item.validate === 'function') {
          //
          return !!item.validate();
        }
        return true;
      }
      return false;
    } else {
      return pathname.startsWith(item.path);
    }
  });
};

export const getCodeFromReturnUrl = (to) => {
  if (to) {
    let url = null,
      _to = to;

    // url params can also be array
    if (Array.isArray(_to)) {
      _to = (_to as string[])[0];
    }

    //
    if (String(_to).startsWith('/')) {
      // we only need domain for URL construction, doesnt matter which one
      const domain = process.env.DOMAIN || 'https://yogobe.com';

      url = new URL(domain + _to);
    } else if (String(_to).startsWith('http')) {
      url = new URL(_to);
    }

    if (url) {
      return (url as URL).searchParams.get('code');
    }
  }

  return null;
};

export const HasValidLiveCodes = (
  liveEventCodes: LiveEventCodesQuery['liveEventCodes']
) => {
  return [
    ...(liveEventCodes?.trial || []),
    ...(liveEventCodes?.wellness || []),
    ...(liveEventCodes?.campaign || []),
  ].some((item) => {
    const date = item.expirationDate
      ? parse(item.expirationDate, 'yyyy-MM-dd', new Date())
      : null;

    if (
      date &&
      isValid(date) &&
      isFuture(date) &&
      item.usageLimit > item.usedIn?.length
    ) {
      return true;
    }

    return false;
  });
};

export function supportsHLS() {
  var video = document.createElement('video');
  return Boolean(
    video.canPlayType('application/vnd.apple.mpegURL') ||
      video.canPlayType('audio/mpegurl')
  );
}

export function isSafariOnMacOS() {
  if (typeof window === 'undefined') {
    return null;
  }

  const userAgent = navigator.userAgent;
  const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);

  // Check if `userAgentData` is available
  if (navigator['userAgentData']) {
    const platform = navigator['userAgentData']?.platform || '';
    const isMac = platform.toLowerCase() === 'macos';
    return isMac && isSafari;
  } else {
    // Fallback for older browsers: detect macOS via user agent
    const isMac = /Macintosh|Mac OS X/i.test(userAgent);
    return isMac && isSafari;
  }
}

export function formatHref(url) {
  if (String(url).startsWith('/') || String(url).startsWith('http')) {
    return url;
  }

  return 'https://' + url;
}

export function convertMinutesToHHMMSS(totalMinutes) {
  const hours = Math.floor(totalMinutes / 60);
  const mins = totalMinutes % 60;

  return (hours ? hours + 'H' : '') + mins + 'M' + '0S';
}

export const fixAnchors = (htmlString) => {
  return htmlString.replace(/href="(www\.[^"]+)"/g, 'href="https://$1"');
};
