/* eslint-disable no-prototype-builtins */
import React, { useEffect, useRef, useMemo } from 'react';
import { ApolloError, ApolloQueryResult } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
import {
  CurrentUser,
  useCurrentUserQuery,
  useCurrentUser2LazyQuery,
  CurrentUserDocument,
  CurrentUser2Document,
  CurrentUserQuery,
  CurrentUser2Query,
} from '@gql/generated';
import AnalyticsManager from '@lib/analytics/manager';

const USER_INITIAL_PROPTETIES = CurrentUserDocument.definitions[0][
  'selectionSet'
]['selections'][0]['selectionSet']['selections'].map(({ name }) => name.value);

// extract the fields in the complex query
const USER_COMPLEX_PROPTETIES = CurrentUser2Document.definitions[0][
  'selectionSet'
]['selections'][0]['selectionSet']['selections'].map(({ name }) => name.value);

/*
  THOUGHTS
  to break the currentUser query into two queries
  we need a way to detect when to load the second query
    - either by adding a parameter to the hook to mark usage as full query, and we load the full query on initial load
    - we make the loading dynamic, we proxy the initial currentUser and when a field on the second part is looked-up 
      we load the second query
    - we can also separate the queries, make two hooks each should be responsible for one part. (some components might need items in the two parts)

    ? how do we handle UI when initial part is loaded & the complex part is loading
      - separate the loading of the two queries?
      - keep one loading, UI that depends on the complex properties will be hidden because of missing data until all is loaded!!

    + need to talk to someone from the backend to know which queries require lookup in other tables
    + generally we should bullet proof the code to not fail when some object are null/undefined and find a good way of loading the rest of the user
*/

// tried using `called` on lazyQuery but its doesn't update fast enought, it causes lots of re-renders

export type UserContextType = [
  CurrentUser,
  {
    loading: boolean;
    refetch(params?: { onlyBaseProperties?: boolean }): RefetchReturnType;
    error: ApolloError;
  },
];
export const UserContext = React.createContext<UserContextType>(null);

type Awaited<T> = T extends PromiseLike<infer U> ? U : T;
type RefetchReturnType =
  | Promise<[ApolloQueryResult<CurrentUserQuery>]>
  | Promise<
      [
        ApolloQueryResult<CurrentUserQuery>,
        ApolloQueryResult<CurrentUser2Query>,
      ]
    >;
const UserProviderHook = (initialUser): UserContextType => {
  const _calledLazy = useRef(false);

  // we can even split `GET_REST_CURRENT_USER` into more queries
  const [loadRestOfUser, { data: lazyUserParts, refetch: refetchLazy }] =
    useCurrentUser2LazyQuery();

  const {
    data: initialUserData,
    loading,
    error,
    refetch: refetchUserBase,
  } = useCurrentUserQuery();

  useEffect(() => {
    // USING setInterval TO SOLVE THE FOLLOWING ISSUE
    // https://github.com/facebook/react/issues/18178
    //
    const ID = setInterval(() => {
      if (_calledLazy.current) {
        loadRestOfUser();
        clearInterval(ID);
      }
    }, 500);

    return () => {
      clearInterval(ID);
    };
  }, []);

  const getProxiedUserObject = (): CurrentUser => {
    if (!initialUserData?.currentUser?.id) {
      return null;
    }
    const fullUser = Object.assign(
      {},
      initialUserData?.currentUser || {},
      lazyUserParts?.currentUser || {}
    ) as CurrentUser;

    const proxied = new Proxy<CurrentUser>(fullUser, {
      // to differentiate between properties that are falsy
      // we check if user has the property, if not we load the query that contains the property
      getOwnPropertyDescriptor(target, prop) {
        if (
          !target.hasOwnProperty(prop) &&
          // if we break the second query into multiple, we can lookup in which query the prop exists and load that
          USER_COMPLEX_PROPTETIES.includes(prop)
        ) {
          if (!_calledLazy.current) {
            _calledLazy.current = true;
          }
          return undefined;
        }
        return {
          enumerable: true,
          configurable: true,
          value: target[prop],
        };
      },
      get(target, prop) {
        if (
          Object.prototype.hasOwnProperty(prop) ||
          ['$$typeof'].includes(String(prop))
        ) {
          return target[prop];
        }
        if (
          // hasn't loaded
          !target.hasOwnProperty(prop) &&
          // if we break the second query into multiple, we can lookup in which query the prop exists and load that
          USER_COMPLEX_PROPTETIES.includes(prop)
        ) {
          if (!_calledLazy.current) {
            _calledLazy.current = true;
          }
        } else {
          if (
            ![...USER_INITIAL_PROPTETIES, ...USER_COMPLEX_PROPTETIES].includes(
              prop
            )
          ) {
            // if we forgot to include a field
            // TODO: convert this is normal error before going PROD
            Sentry.captureException(
              new Error(
                `tried to access an unknown property ${prop as string} on currentUser`
              ),
              {
                level: 'warning',
              }
            );
          }
          return target[prop];
        }
      },
    });

    return proxied;
  };

  const currentUserState = useMemo<CurrentUser>(() => {
    if (typeof window === 'undefined') {
      // during SSR we return pure POJO
      return initialUserData?.currentUser || initialUser || null;
    } else {
      return getProxiedUserObject();
    }
  }, [lazyUserParts?.currentUser, initialUserData?.currentUser, initialUser]);

  useEffect(() => {
    if (initialUserData?.currentUser?.id) {
      Sentry.setUser({
        id: initialUserData?.currentUser?.id,
        email: initialUserData?.currentUser?.email,
      });
    }
  }, [initialUserData?.currentUser?.id]);

  useEffect(() => {
    if (!initialUserData?.currentUser?.id) {
      // reset for future login
      _calledLazy.current = false;
    }
  }, [initialUserData?.currentUser?.id]);

  const refetch = ({ onlyBaseProperties = false } = {}) => {
    // in some cases we need to wait for user to refrech then do an action
    // so we return the promises so we can do await on them
    if (!onlyBaseProperties) {
      // if lazy query was never fired, it's refetch function is undefined
      return Promise.all([refetchUserBase(), refetchLazy?.()]);
    } else {
      return Promise.all([refetchUserBase()]);
    }
  };

  useEffect(() => {
    if (!initialUserData?.currentUser) {
      refetchUserBase();
    }
  }, []);

  return [
    currentUserState,
    {
      loading,
      error: error,
      refetch,
    },
  ];
};

const UserProvider = (props): JSX.Element => {
  const [currentUserState, { loading, error: error, refetch }] =
    UserProviderHook(props.initialValue);

  return (
    <UserContext.Provider
      value={[currentUserState, { loading, error: error, refetch }]}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserProvider;
